// app/javascript/controllers/auto_scroll_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Scroll to the bottom when the controller connects (initial page load)
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.element.scrollTop = this.element.scrollHeight;
  }

  // A method to handle scroll after new content is appended
  scrollAfterAppend() {
    setTimeout(() => this.scrollToBottom(), 50); // Small delay to ensure new content has loaded
  }
}
