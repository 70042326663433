import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="autocomplete"
// app/javascript/controllers/autocomplete_controller.js

export default class extends Controller {
  static targets = ["input", "suggestions"]

  connect() {
    // Check that the controller is initialized
    console.log("Autocomplete controller connected!");
  }

  async fetchSuggestions(event) {
    const query = event.target.value;
    if (query.length < 3) {
      this.clearSuggestions();
      return; // Start searching after 3 characters
    }

    try {
      const response = await fetch(`/geocode/search?query=${encodeURIComponent(query)}`);
      const data = await response.json();

      this.clearSuggestions();
      this.showSuggestions(data.features);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  }

  showSuggestions(features) {
    features.forEach((feature) => {
      const suggestion = document.createElement("div");
      suggestion.classList.add("p-3", "cursor-pointer", "hover:bg-blue-50", "text-gray-700", "border-b", "border-gray-200", "last:border-b-0", "transition-colors");
      suggestion.textContent = feature.place_name;

      // On click, set the input value to the suggestion and clear the dropdown
      suggestion.addEventListener("click", () => {
        this.inputTarget.value = feature.place_name;
        this.clearSuggestions();
      });

      this.suggestionsTarget.appendChild(suggestion);
    });

    this.suggestionsTarget.classList.remove("hidden");
  }

  clearSuggestions() {
    this.suggestionsTarget.innerHTML = "";
    this.suggestionsTarget.classList.add("hidden");
  }

  // Hide suggestions when clicking outside the input or suggestions box
  hideSuggestionsOnClickOutside(event) {
    if (!this.inputTarget.contains(event.target) && !this.suggestionsTarget.contains(event.target)) {
      this.clearSuggestions();
    }
  }
}
