// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import "./js/main.js"
import "./js/vendors/swiper-bundle.min.js"
import "./js/vendors/alpinejs.min.js"
// import "tom-select/dist/css/tom-select.css";
document.addEventListener('turbo:load', function () {
    AOS.init();  // Initialize AOS after the page has been loaded
  });



