import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {
    if (!this.element.tomSelect) {
      this.element.tomSelect = new TomSelect(this.element, {
        create: false,
        maxItems: 1,
        placeholder: "Choose a category...",
        allowEmptyOption: true,
        render: {
          option: function(data, escape) {
            return `<div class="p-2 text-gray-800">${escape(data.text)}</div>`
          },
          item: function(data, escape) {
            return `<div class="flex items-center space-x-2 p-2 rounded bg-blue-100 text-blue-700">
                      <i class="fas fa-check-circle"></i>
                      <span>${escape(data.text)}</span>
                    </div>`
          }
        },
      });
    }

  }
}
