import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="tom-select-profile"
export default class extends Controller {
  static values = { options: Array }

  connect() {
    new TomSelect("#select-specialties", {
      maxItems: null,
      valueField: 'id',
      labelField: 'title',
      searchField: 'title',
      options: this.optionsValue,  // Use the options passed in through data
      create: false,
      plugins: ['remove_button']   // Optional: Adds remove button
    });
  }
}
